<template>
	<CRow>
		<CAlert v-if="this.customerData.email === ''" color="danger">
			<span
				>EL cliente no posee un E-Mail. Por Favor ingresa un E-Mail
				haciendo clic en el bot&oacute;n <b>"Cambiar E-Mail"</b> para
				continuar.</span
			>
			<br />
		</CAlert>
		<CCol sm="12">
			<br />
			<p class="h6 justify-text">
				El cliente
				<b>{{ this.customerData.nombre_comercial }}</b> cuenta con un
				Widget en <b>Infoguia</b> con el siguiente estatus:
			</p>
			<div class="text-center">
				<p class="h6 badge badge-secondary">
					<b>{{ tableCustomer[0].status }}</b>
				</p>
			</div>
			<p class="h6 justify-text">
				En caso de ser necesario, puedes reiniciar el proceso de
				solicitud de autorizaci&oacute;n haciendo clic en el
				bot&oacute;n <b>"Reiniciar"</b>.
			</p>
			<br />
			<p class="h6 justify-text">
				A continuaci&oacute;n podr&aacute;s ver el detalle
				correspondiente a la solicitud de autorizaci&oacute;n que posee
				el cliente.
			</p>
		</CCol>
		<CCol sm="12">
			<CRow>
				<CCol sm="12">
					<b-table
						v-if="tableCustomer.length > 0"
						class="text-left"
						stacked="lg"
						striped
						outlined
						hover
						bordered
						:items="tableCustomer"
						:fields="fieldsTableCustomer"
					>
						<template #cell(actions)>
							<b-button
								@click="changeEmail"
								title="Cambiar E-Mail"
								id="editMail"
								pill
								variant="info"
								size="sm"
								class="mr-1"
							>
								<i class="fas fa-edit"></i>
								Cambiar E-Mail
							</b-button>
						</template>
					</b-table>
				</CCol>
			</CRow>
		</CCol>
		<CCol sm="12">
			<CRow>
				<CCol sm="12">
					<b-table
						v-if="tableCustomerWidget.length > 0"
						class="text-left"
						stacked="lg"
						striped
						outlined
						hover
						bordered
						:items="tableCustomerWidget"
						:fields="fieldsTableWidgetCustomer"
					>
					</b-table>
				</CCol>
			</CRow>
		</CCol>
		<CCol sm="12">
			<CRow class="content-right">
				<CCol sm="12">
					<b-button
						@click="sendRequest"
						title="Reiniciar"
						id="sendMail"
						pill
						:disabled="
							this.customerData.email === '' ? true : false
						"
						variant="success"
						class="mr-1 text-white"
					>
						Reiniciar
					</b-button>
					<b-button
						@click="goBack"
						title="Cancelar"
						id="goBack"
						pill
						variant="warning"
						class="mr-1 text-white"
					>
						Cancelar
					</b-button>
				</CCol>
			</CRow>
		</CCol>
		<edit-email
			v-if="showModalEditEmail"
			:trigger-modal="true"
			:email="customerData.email"
			@closeModal="showModalEditEmail = false"
			@editEmail="newEmail"
		/>
		<success-modal
			v-if="showSuccessModal"
			:triggerModal="true"
			:title-modal="this.titleSuccessModal"
			:text-modal="this.textSuccessModal"
		/>
	</CRow>
</template>

<script>
	import editEmail from '../modals/editEmail.vue';
	import functionsForAdminWidgets from '../../functions/functionsForAdminWidgets';
	import SuccessModal from '../modals/successModal.vue';
	export default {
		name: 'customerWithWidget',
		components: { editEmail, SuccessModal },
		created() {},
		data() {
			return {
				fieldsTableCustomer: [
					{
						key: 'comercialName',
						label: 'Nombre Comercial',
					},
					{
						key: 'contractNumber',
						label: 'Contrato Actual',
					},
					{
						key: 'status',
						label: 'Estatus',
					},
					{
						key: 'email',
						label: 'E-Mail',
					},
					{
						key: 'actions',
						label: 'Cambiar E-Mail',
					},
				],
				fieldsTableWidgetCustomer: [
					{
						key: 'sendDate',
						label: 'Fecha Envío',
					},
					{
						key: 'user',
						label: 'Usuario',
					},
					{
						key: 'authDate',
						label: 'Fecha Autorización',
					},
					{
						key: 'refreshDate',
						label: 'Fecha Refrescamiento',
					},
				],
				showModalEditEmail: false,
				//tableCustomer: [],
				showSuccessModal: false,
				titleSuccessModal: 'Reinicio Exitoso',
				textSuccessModal: '',
			};
		},
		props: {
			customerData: {
				type: Object,
				default: function() {
					return {};
				},
			},
			tableCustomer: {
				type: Array,
				default: function() {
					return [];
				},
			},
			tableCustomerWidget: {
				type: Array,
				default: function() {
					return [];
				},
			},
		},
		methods: {
			changeEmail() {
				this.showModalEditEmail = true;
			},
			newEmail(newEmail) {
				this.customerData.email = newEmail;
				this.tableCustomer[0].email = newEmail;
				this.showModalEditEmail = false;
			},
			sendRequest() {
				let bussinessCode = this.customerData.codigoempresa;
				let dataForPost = {
					email: this.customerData.email,
					idContractClient: this.customerData.idContractClient,
					idCustomers: this.customerData.idCustomers,
				};

				functionsForAdminWidgets
					.postRequest(dataForPost, bussinessCode)
					.then((response) => {
						this.textSuccessModal =
							'Reiniciaste exitosamente la solicitud de autorizaci&oacute;n de <b>' +
							this.customerData.nombre_comercial +
							'</b>';
						this.showSuccessModal = true;
					})
					.catch((error) => {
						console.log(error);
					});
			},
			goBack() {
				this.$emit('goBack');
			},
		},
	};
</script>

<style></style>
