<template>
	<CRow>
		<CAlert v-if="this.customerData.email === ''" color="danger">
			<span
				>EL cliente no posee un E-Mail. Por Favor ingresa un E-Mail
				haciendo clic en el bot&oacute;n <b>"Cambiar E-Mail"</b> para
				continuar.</span
			>
			<br />
		</CAlert>
		<CCol sm="12">
			<CRow>
				<CCol sm="12">
					<br />
					<p class="h6 justify-text">
						El cliente
						<b>{{ this.customerData.nombre_comercial }}</b> no
						cuenta con un widget activo en <b>Infoguia</b>. A
						continuaci&oacute;n se muestra una tabla con la
						informaci&oacute;n del cliente.
					</p>
					<p class="h6 justify-text">
						Para enviar al cliente la solicitud de
						<b>autorizaci&oacute;n</b>, debes hacer clic en el
						bot&oacute;n <b>"Enviar Correo"</b>. Si el cliente no
						posee un E-Mail en la informaci&oacute;n mostrada en la
						tabla, el sistema te mostrar&aacute; una
						notificaci&oacute;n solicitando que ingreses un E-Mail
						para continuar con el proceso.
					</p>
					<p class="h6 justify-text">
						Si no deseas realizar el env&iacute;o de la solicitud
						a&uacute;n, puedes hacer clic en el bt&oacute;n
						<b>"Cancelar"</b> para regresar a la secci&oacute;n
						anterior y buscar otro cliente.
					</p>
				</CCol>
			</CRow>
		</CCol>
		<CCol sm="12">
			<CRow>
				<CCol sm="12">
					<b-table
						v-if="tableCustomer.length > 0"
						class="text-left"
						stacked="lg"
						striped
						outlined
						hover
						bordered
						:items="tableCustomer"
						:fields="fieldsTableCustomer"
					>
						<template #cell(actions)>
							<b-button
								@click="changeEmail"
								title="Cambiar E-Mail"
								id="editMail"
								pill
								variant="info"
								size="sm"
								class="mr-1"
							>
								<i class="fas fa-edit"></i>
								Cambiar E-Mail
							</b-button>
						</template>
					</b-table>
				</CCol>
			</CRow>
		</CCol>
		<CCol sm="12">
			<CRow class="content-right">
				<CCol sm="12">
					<b-button
						@click="sendRequest"
						title="Enviar Correo"
						id="sendMail"
						pill
						:disabled="
							this.customerData.email === '' ? true : false
						"
						variant="success"
						class="mr-1 text-white"
					>
						Enviar Correo
					</b-button>
					<b-button
						@click="goBack"
						title="Cancelar"
						id="goBack"
						pill
						variant="warning"
						class="mr-1 text-white"
					>
						Cancelar
					</b-button>
				</CCol>
			</CRow>
		</CCol>
		<edit-email
			v-if="showModalEditEmail"
			:trigger-modal="true"
			:email="customerData.email"
			@closeModal="showModalEditEmail = false"
			@editEmail="newEmail"
		/>
		<success-modal
			v-if="showSuccessModal"
			:triggerModal="true"
			:title-modal="this.titleSuccessModal"
			:text-modal="this.textSuccessModal"
		/>
	</CRow>
</template>

<script>
	import editEmail from '../modals/editEmail.vue';
	import functionsForAdminWidgets from '../../functions/functionsForAdminWidgets';
	import SuccessModal from '../modals/successModal.vue';
	export default {
		name: 'noWidgetComponent',
		components: { editEmail, SuccessModal },
		created() {},
		data() {
			return {
				fieldsTableCustomer: [
					{
						key: 'comercialName',
						label: 'Nombre Comercial',
					},
					{
						key: 'contractNumber',
						label: 'Contrato Actual',
					},
					{
						key: 'status',
						label: 'Estatus',
					},
					{
						key: 'email',
						label: 'E-Mail',
					},
					{
						key: 'actions',
						label: 'Cambiar E-Mail',
					},
				],
				showModalEditEmail: false,
				//tableCustomer: [],
				showSuccessModal: false,
				titleSuccessModal: 'Env&iacute;o Exitoso',
				textSuccessModal: '',
			};
		},
		props: {
			customerData: {
				type: Object,
				default: function() {
					return {};
				},
			},
			tableCustomer: {
				type: Array,
				default: function() {
					return [];
				},
			},
		},
		methods: {
			changeEmail() {
				this.showModalEditEmail = true;
			},
			newEmail(newEmail) {
				this.customerData.email = newEmail;
				this.tableCustomer[0].email = newEmail;
				this.showModalEditEmail = false;
			},
			sendRequest() {
				let bussinessCode = this.customerData.codigoempresa;
				let dataForPost = {
					email: this.customerData.email,
					idContractClient: this.customerData.idContractClient,
				};

				functionsForAdminWidgets
					.postRequest(dataForPost, bussinessCode)
					.then((response) => {
						this.textSuccessModal =
							'Enviaste exitosamente la solicitud de autorizaci&oacute;n a <b>' +
							this.customerData.nombre_comercial +
							'</b>';
						this.showSuccessModal = true;
					})
					.catch((error) => {
						console.log(error);
					});
			},
			goBack() {
				this.$emit('goBack');
			},
		},
	};
</script>

<style></style>
