<template>
	<b-modal
		v-model="showModal"
		centered
		no-close-on-backdrop
		:header-bg-variant="this.color"
		header-text-variant="light"
		style="text-align:justify;"
		size="lg"
	>
		<CRow>
			<CCol>
				<p v-html="textModal"></p>
			</CCol>
		</CRow>
		<template v-slot:modal-header>
			<h6 class="modal-title" v-html="titleModal"></h6>
			<CButtonClose @click="closeModal" class="text-white" />
		</template>
		<template v-slot:modal-footer>
			<b-button
				@click="closeModal"
				pill
				variant="warning"
				class="mr-1 text-white"
				>Cerrar
			</b-button>
		</template>
	</b-modal>
</template>

<script>
	export default {
		name: 'errorModal',
		props: {
			titleModal: {
				type: String,
				default: 'Error!!!',
			},
			color: {
				type: String,
				default: 'danger',
			},
			textModal: {
				type: String,
				default: 'Ocurri&oacute; un error',
			},
			triggerModal: {
				type: Boolean,
				default: function() {
					return false;
				},
			},
		},
		data() {
			return {
				showModal: this.triggerModal,
			};
		},
		methods: {
			closeModal() {
				this.showModal = false;
			},
		},
	};
</script>
