<template>
	<CRow>
		<CCol sm="6" style="margin-top: 20px;">
			<p class="h6 justify-text">
				Nombre Comercial
			</p>
			<autocomplete
				:search="search"
				placeholder="Ingresa el Nombre de la Empresa"
				aria-label="Nombre de la empresa"
				:get-result-value="getResultValue"
				@submit="onSubmit"
			></autocomplete
			><br />
			<CAlert color="danger" v-if="this.errorAutocomplete !== ''">{{
				this.errorAutocomplete
			}}</CAlert>
		</CCol>
	</CRow>
</template>

<script>
	import functionsForAdminWidgets from '../../functions/functionsForAdminWidgets';
	export default {
		name: 'customerAutoComplete',
		created() {},
		data() {
			return {
				errorAutocomplete: '',
			};
		},
		methods: {
			search(input) {
				return new Promise((resolve) => {
					this.errorAutocomplete = '';
					if (input.length < process.env.VUE_APP_CANT_LETTERS_INPUT) {
						return resolve([]);
					}
					functionsForAdminWidgets
						.getCustomersAutocomplete(encodeURI(input))
						.then((response) => {
							this.errorAutocomplete = '';
							if (response.length === 0) {
								this.errorAutocomplete =
									'OOPS!!! No se encontró lo que buscabas.';
							}
							resolve(response);
						})
						.catch((error) => {
							this.errorAutocomplete =
								'OOPS!!! Ocurió un error con la búsqueda.';
							console.log(error);
							return resolve([]);
						});
				});
			},
			getResultValue(result) {
				return result.nombre_comercial;
			},
			onSubmit(result) {
				this.$emit('submit', result);
			},
		},
	};
</script>

<style></style>
