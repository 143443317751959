<template>
	<div>
		<CContainer class="d-flex content-left">
			<CRow>
				<CCard>
					<CCardHeader>
						<h4 class="center-text">
							Solicitar Autorizaci&oacute;n
						</h4>
					</CCardHeader>
					<CCardBody>
						<div
							id="information"
							v-show="this.showContent === 'information'"
						>
							<CRow>
								<CCol sm="12">
									<br />
									<p class="h6 justify-text">
										En este m&oacute;dulo podr&aacute;s
										seleccionar un cliente y enviarle un
										correo electr&oacute;nico para solicitar
										la
										<b>Autorizaci&oacute;n</b> a su cuenta
										de <b>Instagram</b> y mostrar su
										<b>Feed</b> en el site de
										<b>Infoguia</b>.
									</p>
									<p class="h6 justify-text">
										Para
										<b>solicitar la autorizaci&oacute;n</b>
										ingresa en la siguiente casilla el
										<b>Nombre Comercial del Cliente</b>. A
										medida que escribas el
										<b>Nombre Comercial</b>, se
										mostrar&aacute;n los nombres que
										coincidan con tu b&uacute;squeda y al
										hacer clic en alguno de ellos se
										seleccionar&aacute;
										autom&aacute;ticamente:
									</p>
								</CCol>
							</CRow>
							<customer-auto-complete @submit="submitCustomer" />
						</div>
						<!-- no widget -->
						<no-widget-component
							v-if="this.showContent === 'noWidget'"
							:customer-data="this.customerData"
							:table-customer="this.tableCustomer"
							@goBack="goBack"
						/>
						<customer-with-widget
							v-if="this.showContent === 'withWidget'"
							:customer-data="this.customerData"
							:table-customer="this.tableCustomer"
							:table-customer-widget="this.tableWidgetCustomer"
							@goBack="goBack"
						/>
					</CCardBody>
				</CCard>
			</CRow>
			<!-- modal de cliente con registros duplicados -->
			<CModal
				:show.sync="modalDuplicate"
				:close-on-backdrop="false"
				:centered="true"
				title="Cliente Duplicado"
				size="lg"
				color="info"
				style="text-align:justify;"
			>
				<CRow class="justify-content-left">
					<CCol>
						<p>
							El cliente seleccionado posee 2 empresas registradas
							con contratos activos usando el mismo RIF.
						</p>
						<p>
							Para continuar con el proceso por favor selecciona
							<b>uno</b> de la siguiente lista y haz clic en el
							bt&oacute;n <b>"Continuar"</b>.
						</p>
						<b-table
							v-if="tableDuplicates.length > 0"
							class="text-left"
							stacked="lg"
							striped
							outlined
							hover
							bordered
							:items="tableDuplicates"
							:fields="fieldsTableDuplicates"
						>
							<template #cell(actions)="row">
								<b-form-radio
									name="some-radios"
									:value="row.item.idContractClient"
									v-model="selected"
									class="center-text"
									@change="changeRadioButton(row.item)"
								></b-form-radio>
							</template>
						</b-table>
					</CCol>
				</CRow>
				<template #header>
					<h6 class="modal-title">Cliente Duplicado</h6>
					<CButtonClose @click="closeModal" class="text-white" />
				</template>
				<template #footer>
					<b-button
						@click="getDetails"
						pill
						variant="success"
						size="lg"
						class="mr-1"
						:disabled="selected === '' ? true : false"
						>Continuar
					</b-button>
					<b-button
						@click="closeModal"
						pill
						variant="warning"
						size="lg"
						class="mr-1 text-white"
						>Cancelar
					</b-button>
				</template>
			</CModal>
			<!-- modal de error -->
			<error-modal
				v-if="showModalError"
				:trigger-modal="true"
				:title-modal="titleModalError"
				:text-modal="textModalError"
			/>
		</CContainer>
	</div>
</template>

<script>
	import functionsForAdminWidgets from '../../functions/functionsForAdminWidgets';
	import md5 from 'blueimp-md5';
	import noWidgetComponent from '../../components/adminWidgets/customerWithoutWidget.vue';
	import { httpCodes } from '../../constants/httpCodes';
	import ErrorModal from '../../components/modals/errorModal.vue';
	import CustomerAutoComplete from '../../components/adminWidgets/customerAutoComplete.vue';
	import CustomerWithWidget from '../../components/adminWidgets/customerWithWidget.vue';
	export default {
		components: {
			noWidgetComponent,
			ErrorModal,
			CustomerAutoComplete,
			CustomerWithWidget,
		},
		name: 'RequestAuth',
		title: 'Info Feed | Infoguia',
		data() {
			return {
				errorAutocomplete: '',
				customerData: {},
				customerContractData: {},
				widgetCustomerData: {},
				showContent: 'information',
				modalDuplicate: false,
				tableDuplicates: [],
				fieldsTableDuplicates: [
					{
						key: 'comercialName',
						label: 'Nombre Comercial',
					},
					{
						key: 'contractNumber',
						label: 'Contrato Actual',
					},
					{
						key: 'email',
						label: 'E-Mail',
					},
					{
						key: 'actions',
						label: 'Seleccionar',
					},
				],
				tableCustomer: [],
				tableWidgetCustomer: [],
				selected: '',
				showModalError: false,
				titleModalError: '',
				textModalError: '',
			};
		},
		beforeCreate: function() {},
		created() {},
		methods: {
			submitCustomer(result) {
				if (result !== undefined) {
					this.customerData = result;
					let encryptedDif = md5(result.rif);
					this.showModalError = false;
					functionsForAdminWidgets
						.getContractCustomer(encryptedDif)
						.then((response) => {
							//resolve(response);
							this.customerContractData = response;
							if (response.length > 1) {
								for (
									let index = 0;
									index < response.length;
									index++
								) {
									this.tableDuplicates[index] = {
										comercialName: response[index].name,
										contractNumber:
											response[index].contracts,
										email: this.customerData.email,
										idContractClient:
											response[index].idContractClient,
									};
								}
								this.modalDuplicate = true;
							} else {
								this.customerData.idContractClient =
									response[0].idContractClient;
								this.customerData.contracts =
									response[0].contracts;
								this.getDetails();
							}
						})
						.catch((error) => {
							let errorCode = error.response.data.code;
							if (errorCode == httpCodes.notFound) {
								this.showModalError = true;
								this.titleModalError = 'Cliente Sin Contrato';
								this.textModalError =
									'El cliente <b>' +
									this.customerData.nombre_comercial +
									'</b> no posee un contrato activo en el <b> Sistema de Contratos </b>';
							}
						});
				}
			},
			getDetails() {
				let newObject = {};
				if (this.modalDuplicate === true) {
					this.modalDuplicate = false;
				}
				functionsForAdminWidgets
					.getCustomersDetails(this.customerData.codigoempresa)
					.then((response) => {
						newObject = {
							comercialName: this.customerData.nombre_comercial,
							contractNumber: this.customerData.contracts,
							status: 'S/I',
							email: this.customerData.email,
						};
						if (response.length === 0) {
							this.showContent = 'noWidget';
						} else {
							let objetTableWidgetCustomer = {};
							this.widgetCustomerData = response[0];
							newObject.status = response[0].status.name;
							objetTableWidgetCustomer = {
								sendDate: response[0].sendDate,
								authDate:
									response[0].authDate == null
										? 'S/I'
										: response[0].authDate,
								refreshDate:
									response[0].longTokenDate == null
										? 'S/I'
										: response[0].longTokenDate,
								user: response[0].user.name,
							};
							this.tableWidgetCustomer.push(
								objetTableWidgetCustomer
							);
							this.showContent = 'withWidget';
						}
						this.tableCustomer.push(newObject);
					})
					.catch((error) => {
						console.log(error);
					});
			},
			closeModal() {
				this.modalDuplicate = false;
			},
			changeRadioButton(value) {
				this.customerData.idContractClient = value.idContractClient;
				this.customerData.contracts = value.contractNumber;
			},
			goBack() {
				this.showContent = 'information';
				this.tableCustomer = [];
				this.tableDuplicates = [];
				this.customerData = {};
				this.customerContractData = {};
				this.tableWidgetCustomer = [];
			},
		},
		mounted() {},
	};
</script>
